
  import { defineComponent, computed } from "vue"
  import { shortDate, dateTime } from "@/utils/helper"
  import { ResourceObject } from '@/models/jsonapi'
  import { timeLeft, misMatchDict, feedbackType, feedbackDescription, feedbackColorClass } from "@/models/feedbacks";
  // import { User } from "@/models/users";
  import { fullName } from "@/utils/dataExtractors";

  // interface Props {
  //   contract: ResourceObject<string, Contract>
  //   seller?: ResourceObject<string, User>
  // }

  export default defineComponent({
    name: 'Feedback',
    props: {
      feedback: {
        type: Object,
        required: true
      },
      // seller: { type: Object },
    },
    setup(props) {
      const present = (val) => val != null && val != undefined
      // const designation = computed(() => {
      //   return props.contract.attributes?.household?.attributes?.designation
      // })

      const fbAtts = computed(()=>props.feedback.attributes)
      const type = computed(() => feedbackType(props.feedback))
      const description = computed(() => feedbackDescription(props.feedback))
      const colorClass = computed(() => feedbackColorClass(props.feedback))
      const timeRemaining = timeLeft(props.feedback)
      const issues = misMatchDict(props.feedback)

      return {
        shortDate,
        dateTime,
        present,
        fbAtts,
        fullName,
        type,
        description,
        colorClass,
        timeRemaining,
        issues
      }
    }
  })
