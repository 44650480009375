
  import { defineComponent, ref, computed, watch, getCurrentInstance } from "vue"
  import TitleActionsNavbar from "@/components/shared/TitleActionsNavbar.vue";
  import { Visor } from "@/models/visors";
  import { Agency } from "@/models/agencies";
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { resourceUrl } from "@/utils/dataExtractors"
  import { CollectionResourceDoc, ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { BvTableCtxObject } from "bootstrap-vue";
  import Tickets from "@/components/shared/Tickets.vue"
  import { watchForTicketIds } from "@/view_scripts/tickets"
  import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
  import TicketEdit from "@/components/shared/TicketEdit.vue"
  import Feedback from "@/components/shared/Feedback.vue";

  interface Props {
    id: string
  }

  export default defineComponent({
    components: {
      TitleActionsNavbar,
      Tickets,
      TicketEdit,
      Feedback
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const visor = computed<ResourceObject<string, Visor>>(() => root.$store.state.visors.element)
      const feedback = computed((fb:any) => {
        if (!visor.value) return null
        const feedbacks = visor.value.attributes?.feedbacks
        if (!feedbacks || feedbacks.length == 0) return null
        const la = visor.value?.attributes?.lastActivated
        if (!la) return null
        const filteredFbs = feedbacks.filter((fb2: any) => fb2.attributes.visorActivation === la )
        if (filteredFbs.length === 1) {return filteredFbs[0]} else {return null}
      })
      const agency = ref<ResourceObject<string, Agency>>()
      const newTicketModal = ref()
      const careStayTable = ref()
      const ticketIds = ref<string[]>([])
      root.$store.dispatch('visors/get', { id: props.id })

      const totalCareStayItems = computed<number>(() => visor.value.attributes?.careStays?.length ?? 0)

      const getCareStayItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          // 'sort': '-createdAt',
          include: 'care_stays,tickets,agency,feedbacks'
        }
        const resp = await root.$store.dispatch('visors/get', { id: props.id, queryParams: queryParams })
        ticketIds.value = extractRelationshipIds({data: [resp]}, 'tickets')
        return visor.value.attributes?.care_stays ?? []
      }

      const columns = ref([
      {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          label: "Status",
          title: "Status",
          key: 'attributes.stage',
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          label: "Start",
          title: "Start",
          key: 'attributes.billStart',
          sortable: true,
          options: {type: 'date'}
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          label: "Ende",
          title: "Ende",
          key: 'attributes.billEnd',
          sortable: true,
          options: {type: 'date'}
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          label: "Provision",
          title: "Provision",
          key: 'attributes.provSeller',
          sortable: true,
          options: {
            contentFunction: (data: any) => {
              const pp = data.item.attributes.provPfs
              const ps = data.item.attributes.provSeller
              return pp+ps
            }
          }
        }

      ])

      const titleActions = computed(() => [newTicketAction(newTicketModal)])


      const ticketCreated = () => {
        newTicketModal.value.hide()
        careStayTable.value.refreshItems()
      }

      return {
        visor,
        getCareStayItems,
        totalCareStayItems,
        columns,
        ticketIds,
        resourceUrl,
        titleActions,
        newTicketModal,
        ticketCreated,
        careStayTable,
        feedback
      }
    }
  })
